/* eslint-disable @next/next/no-html-link-for-pages */
import React from 'react';
import { useTranslation } from 'next-i18next';
import { format } from 'date-fns';
import {
  AYD_SUPPORT_EMAIL,
  FACEBOOK_URL,
  INSTAGRAM_URL,
  LINKEDIN_URL,
  NOTION_URL,
} from '../../model/constants';
import FooterLinksBlock from '../footer-links-block/footer-links-block.component';
import useLocalizedPath from '../../hooks/use-localized-path/use-localized-path';

type FooterProps = {};

const Footer: React.FC<FooterProps> = () => {
  const { t } = useTranslation();

  const getLocalizedPath = useLocalizedPath();

  return (
    <>
      <footer className="footer-new">
        <div className="footer-new__top">
          <div className="footer-new__top_col">
            <div className="footer-new__top_col-inner">
              <div className="footer-new__group-title">
                {t('LAYOUTS.LANDING.FOOTER.COMPANY_NAME')}
              </div>
              <div className="footer-new__top_col-text">{t('LAYOUTS.LANDING.FOOTER.SLOGAN')}</div>
            </div>
          </div>
          <div className="footer-new__top_col">
            <div className="footer-new__top_col-inner">
              <div className="footer-new__group-title">
                {t('LAYOUTS.LANDING.FOOTER.WHAT_WE_TREAT')}
              </div>
              <a href={getLocalizedPath('/hair-loss')} className="footer-new__top_col-text">
                {t('LAYOUTS.LANDING.FOOTER.WHAT_WE_TREAT.HAIR_LOSS')}
              </a>
              <a
                href={getLocalizedPath('/erectile-dysfunction')}
                className="footer-new__top_col-text"
              >
                {t('LAYOUTS.LANDING.FOOTER.WHAT_WE_TREAT.ERECTILE_DYSFUNCTION')}
              </a>
              <a
                href={getLocalizedPath('/premature-ejaculation')}
                className="footer-new__top_col-text"
              >
                {t('LAYOUTS.LANDING.FOOTER.WHAT_WE_TREAT.PREMATURE_EJACULATION')}
              </a>
              <a href={getLocalizedPath('/skincare')} className="footer-new__top_col-text">
                {t('LAYOUTS.LANDING.FOOTER.WHAT_WE_TREAT.SKINCARE')}
              </a>
              <a href={getLocalizedPath('/weight-loss')} className="footer-new__top_col-text">
                {t('LAYOUTS.LANDING.FOOTER.WHAT_WE_TREAT.WEIGHT_LOSS')}
              </a>
            </div>
          </div>
        </div>
        <div className="footer-new__main">
          <FooterLinksBlock title={t('LAYOUTS.LANDING.FOOTER.DETAILS.TITLE')}>
            {/* <a href={getLocalizedPath('/facts-and-advice')} className="footer-new__main_link"> */}
            {/*  <span className="footer-new__main_links-separator" /> */}
            {/*  {t('LAYOUTS.LANDING.FOOTER.DETAILS.FACTS_AND_ADVICES')} */}
            {/* </a> */}
            <a href={getLocalizedPath('/our-doctors')} className="footer-new__main_link">
              <span className="footer-new__main_links-separator" />
              {t('LAYOUTS.LANDING.FOOTER.DETAILS.OUR_DOCTORS')}
            </a>
            <a href={getLocalizedPath('/pharmacy')} className="footer-new__main_link">
              <span className="footer-new__main_links-separator" />
              {t('LAYOUTS.LANDING.FOOTER.DETAILS.PHARMACY')}
            </a>
            <a href={getLocalizedPath('/intro-medical-general')} className="footer-new__main_link">
              <span className="footer-new__main_links-separator" />
              {t('LAYOUTS.LANDING.FOOTER.DETAILS.MEDICAL_GENERAL_INTRO')}
            </a>
            <a href={getLocalizedPath('/faq')} className="footer-new__main_link">
              <span className="footer-new__main_links-separator" />
              {t('LAYOUTS.LANDING.FOOTER.DETAILS.FAQ')}
            </a>
          </FooterLinksBlock>
          <FooterLinksBlock title={t('LAYOUTS.LANDING.FOOTER.ABOUT.TITLE')}>
            <a href={getLocalizedPath('/about')} className="footer-new__main_link">
              <span className="footer-new__main_links-separator" />
              {t('LAYOUTS.LANDING.FOOTER.ABOUT.INFO')}
            </a>
            <a className="footer-new__main_link" href={NOTION_URL} target="_blank" rel="noreferrer">
              <span className="footer-new__main_links-separator" />
              {t('LAYOUTS.LANDING.FOOTER.ABOUT.CAREER')}
            </a>
          </FooterLinksBlock>
          <FooterLinksBlock title={t('LAYOUTS.LANDING.FOOTER.LEGAL.TITLE')}>
            <a href={getLocalizedPath('/terms-and-conditions')} className="footer-new__main_link">
              <span className="footer-new__main_links-separator" />
              {t('LAYOUTS.LANDING.FOOTER.LEGAL.TERMS_AND_CONDITIONS')}
            </a>
            <a href={getLocalizedPath('/privacy-policy')} className="footer-new__main_link">
              <span className="footer-new__main_links-separator" />
              {t('LAYOUTS.LANDING.FOOTER.LEGAL.PRIVACY_POLICY')}
            </a>
            <a href={getLocalizedPath('/cookie-policy')} className="footer-new__main_link">
              <span className="footer-new__main_links-separator" />
              {t('LAYOUTS.LANDING.FOOTER.LEGAL.COOKIE_POLICY')}
            </a>
            <a href={getLocalizedPath('/purchase-policy')} className="footer-new__main_link">
              <span className="footer-new__main_links-separator" />
              {t('LAYOUTS.LANDING.FOOTER.LEGAL.PURCHASE_POLICY')}
            </a>
          </FooterLinksBlock>
          <FooterLinksBlock title={t('LAYOUTS.LANDING.FOOTER.CONTACTS.TITLE')}>
            <a
              href={INSTAGRAM_URL}
              target="_blank"
              className="footer-new__main_link"
              rel="noreferrer"
            >
              <span className="footer-new__main_links-separator" />
              {t('LAYOUTS.LANDING.FOOTER.CONTACTS.INSTAGRAM')}
            </a>
            <a
              href={FACEBOOK_URL}
              target="_blank"
              className="footer-new__main_link"
              rel="noreferrer"
            >
              <span className="footer-new__main_links-separator" />
              {t('LAYOUTS.LANDING.FOOTER.CONTACTS.FACEBOOK')}
            </a>
            <a
              href={LINKEDIN_URL}
              target="_blank"
              className="footer-new__main_link"
              rel="noreferrer"
            >
              <span className="footer-new__main_links-separator" />
              {t('LAYOUTS.LANDING.FOOTER.CONTACTS.LINKEDIN')}
            </a>
            <a href={`mailto:${AYD_SUPPORT_EMAIL}`} className="footer-new__main_link">
              <span className="footer-new__main_links-separator" />
              {AYD_SUPPORT_EMAIL}
            </a>
          </FooterLinksBlock>
        </div>
        <div className="footer-new__bottom">
          <a href={getLocalizedPath('/')} aria-current="page" className="footer-new__bottom_logo">
            <img src="/img/logo-white.svg" />
          </a>
          <div className="footer-new__bottom_copyright">
            {t('LAYOUTS.LANDING.FOOTER.COPY', {
              date: format(new Date(), 'yyyy'),
            })}
          </div>
        </div>
      </footer>
      <div className="footer-new__logos">
        <div className="footer-new__logos_row">
          <a
            href="https://www.legitscript.com/websites/?checker_keywords=ayd.se"
            target="_blank"
            title="Verify LegitScript Approval"
            rel="noreferrer"
          >
            <img
              src="https://static.legitscript.com/seals/16432214.png"
              alt="LegitScript approved"
              width="120"
              height="100"
            />
          </a>
          <div className="footer-new__logos_pharmacy">
            <a
              href="https://www.lakemedelsverket.se/e-handlare#A00028"
              target="_blank"
              rel="noreferrer"
            >
              <img src="/img/sweden-pharmacy-consent.png" />
            </a>
            <span className="footer-new__logos_pharmacy-text">
              {t('LAYOUTS.LANDING.FOOTER.PHARMACY_NAME')}
            </span>
          </div>
        </div>
        <div className="footer-new__logos_row footer-new__logos_payment-methods">
          <img src="/img/logos/visa-card.svg" />
          <img src="/img/logos/mastercard.svg" />
          <img src="/img/logos/amex-card.svg" />
          <img src="/img/logos/google-pay.svg" />
          <img src="/img/logos/apple-pay.svg" />
        </div>
      </div>
    </>
  );
};

export default Footer;
